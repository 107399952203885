export default {
  props: {},
  data() {
    return {
      thirdMenu: []
    };
  },
  created() {
    if (this.filePath) {
      this.getButtons();
      this.thirdMenu = this.thirdMenu.filter((button) => {
        if ((button.event.name === `${this.service}:mainTask:generatePDF` || button.event.name === `${this.service}:mainTask:mergePDF`) && this.taskType === 'appraisal') {
          return false
        }
        if (button.event.name === `${this.service}:appraisal:sendAttachmentToTu` || button.event.name === `${this.service}:appraisal:sendAttachmentsToVig`) {
          if (this.taskType === 'main') {
            return false
          }
        }
        return true
      })
    }
  },
  methods: {
    $showThirdMenu(tab) {
      if (tab === "attachments") {
        this.$events.$emit("dashboard:menu:taskTab", this.thirdMenu);
      }
    },
    getButtons() {
      if (this.isAllowedToEdit) {
        this.thirdMenu = [
          {
            event: {
              name: `${this.service}:${this.taskType}:uploadAttachments`
            },
            icon: "save",
            label: "pages.taskDetails.attachments.buttons.addAttachments",
            acl: { security: false }
          },
          {
            event: { name: `task:attachments:invertSelection` },
            icon: "check-square",
            label: "pages.taskDetails.attachments.buttons.invertSelection",
            acl: { security: false }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:downloadSelectedAttachments`
            },
            icon: "download",
            label: "pages.taskDetails.attachments.buttons.downloadSelected",
            acl: { security: false }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:downloadAttachments`
            },
            icon: "download",
            label: "pages.taskDetails.attachments.buttons.downloadAll",
            acl: { security: false }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:removeSelectedAttachments`
            },
            icon: "trash-2",
            label: "pages.taskDetails.attachments.buttons.deleteSelected",
            acl: { service: [this.service, "storage"], action: "delete_files" }
          },
          // {
          //   event: { name: `${this.service}:${this.taskType}:rotateAttachments` },
          //   icon: 'rotate-cw',
          //   label: 'Obróć o 90 stopni',
          //   acl: { service: [this.service, 'storage'], action: 'put_files' }
          // },
          {
            event: {
              name: `${this.service}:${this.taskType}:sendAttachmentToAudanet`
            },
            icon: "skip-forward",
            label: "pages.taskDetails.attachments.buttons.sendToAudanet",
            acl: { service: this.service, action: "post_audanet_attachment" }
          },
          {
            event: {
              name: `${this.service}:mainTask:generatePDF`
            },
            icon: "skip-forward",
            label: "PDF Zdjęcia",
            acl: {
              service: this.service,
              action: "post_generate_pdf"
            }
          },
          {
            event: {
              name: `${this.service}:mainTask:mergePDF`
            },
            icon: "skip-forward",
            label: "Scal Ekspertyzę PDF",
            acl: {
              service: this.service,
              action: "post_merge_pdf"
            }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:sendAttachmentToTu`
            },
            icon: "skip-forward",
            label: "Wyślij do TU",
            acl: { service: this.service, action: ["post_attachments_transfer", "post_transfer_files"], allowOneOfActions: true }
          },
          {
            event: {
              name: `${this.service}:appraisal:sendAttachmentsToVig`
            },
            icon: "skip-forward",
            label: "Wyślij do VIG",
            acl: {
              service: this.service,
              action: "post_attachments_to_vig"
            }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:sendAttachmentToGeneraliSk`
            },
            icon: "skip-forward",
            label: 'pages.taskDetails.attachments.buttons.sendToGeneraliSk',
            acl: {
              service: this.service,
              action: "send_files_to_generali_slovakia"
            }
          },
          {
            event: { name: `toggleAttachmentsView` },
            icon: "settings",
            label: "pages.taskDetails.attachments.buttons.showAll",
            acl: { security: false }
          }
        ];
      } else {
        this.thirdMenu = [
          {
            event: { name: `task:attachments:invertSelection` },
            icon: "check-square",
            label: "pages.taskDetails.attachments.buttons.invertSelection",
            acl: { security: false }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:downloadSelectedAttachments`
            },
            icon: "download",
            label: "pages.taskDetails.attachments.buttons.downloadSelected",
            acl: { security: false }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:downloadAttachments`
            },
            icon: "download",
            label: "pages.taskDetails.attachments.buttons.downloadAll",
            acl: { security: false }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:sendAttachmentToAudanet`
            },
            icon: "skip-forward",
            label: "pages.taskDetails.attachments.buttons.sendToAudanet",
            acl: { service: this.service, action: "post_audanet_attachment" }
          },
          {
            event: {
              name: `${this.service}:mainTask:generatePDF`
            },
            icon: "skip-forward",
            label: "PDF Zdjęcia",
            acl: {
              service: this.service,
              action: "post_generate_pdf"
            }
          },
          {
            event: {
              name: `${this.service}:mainTask:mergePDF`
            },
            icon: "skip-forward",
            label: "Scal Ekspertyzę PDF",
            acl: {
              service: this.service,
              action: "post_merge_pdf"
            }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:sendAttachmentToTu`
            },
            icon: "skip-forward",
            label: "Wyślij do TU",
            acl: { service: this.service, action: ["post_attachments_transfer", "post_transfer_files"], allowOneOfActions: true }
          },
          {
            event: {
              name: `${this.service}:appraisal:sendAttachmentsToVig`
            },
            icon: "skip-forward",
            label: "Wyślij do VIG",
            acl: {
              service: this.service,
              action: "post_attachments_to_vig"
            }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:sendAttachmentToGeneraliSk`
            },
            icon: "skip-forward",
            label: "pages.taskDetails.attachments.buttons.sendToGeneraliSk",
            acl: {
              service: this.service,
              action: "send_files_to_generali_slovakia"
            }
          },
          {
            event: { name: `toggleAttachmentsView` },
            icon: "settings",
            label: "pages.taskDetails.attachments.buttons.showAll",
            acl: { security: false }
          }
        ];
      }
    },
    getViewModeButtons() {
      if (this.isAllowedToEdit) {
        this.thirdMenu = [
          {
            event: {
              name: `${this.service}:${this.taskType}:uploadAttachments`
            },
            icon: "save",
            label: "pages.taskDetails.attachments.buttons.addAttachments",
            acl: { security: false }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:downloadSelectedAttachmentsViewMode`
            },
            icon: "download",
            label: "pages.taskDetails.attachments.buttons.downloadSelected",
            acl: { security: false }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:downloadAttachments`
            },
            icon: "download",
            label: "pages.taskDetails.attachments.buttons.downloadAll",
            acl: { security: false }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:removeSelectedAttachmentsViewMode`
            },
            icon: "trash-2",
            label: "pages.taskDetails.attachments.buttons.deleteSelected",
            acl: { service: [this.service, "storage"], action: "delete_files" }
          },
          {
            event: { name: `toggleAttachmentsView` },
            icon: "settings",
            label: "pages.taskDetails.attachments.buttons.showByCategory",
            acl: { security: false }
          }
        ];
      } else {
        this.thirdMenu = [
          {
            event: {
              name: `${this.service}:${this.taskType}:downloadSelectedAttachmentsViewMode`
            },
            icon: "download",
            label: "Pobierz zaznaczone",
            acl: { security: false }
          },
          {
            event: {
              name: `${this.service}:${this.taskType}:downloadAttachments`
            },
            icon: "download",
            label: "Pobierz wszystkie",
            acl: { security: false }
          },
          {
            event: { name: `toggleAttachmentsView` },
            icon: "settings",
            label: "Pokaż według kategorii",
            acl: { security: false }
          }
        ];
      }
    }
  }
};
