export default {
  data () {
    return {}
  },
  computed: {
    isAllowedToEdit () {
      if (this.$store.state.base.user.roles.includes('ROLE_ADMIN') ) {
        return true
      }
      if (this.$store.state.base.user.roles.includes('ROLE_SUPER_ADMIN')) {
        return true
      }

      if (this.taskType === 'appraisal') {
        if (this.taskState.name === 'zrealizowane') {
          return this.blockAppraiser()
        } else {
          return this.blockFinal()
        }
      } else {
        return this.blockAppraiser() && this.blockFinal()
      }
    }
  },
  methods: {
    blockAppraiser() {
      if (['intgen', 'intbls', 'intvig', 'intvhs'].includes(this.service)) {
        return !this.$store.state.base.user.roles.includes('ROLE_RZECZOZNAWCA_ZEWN')
      }

      return !(this.$store.state.base.user.roles.includes('ROLE_RZECZOZNAWCA_BLS') || this.$store.state.base.user.roles.includes('ROLE_RZECZOZNAWCA_ZEWN'))
    },
    blockFinal() {
      if (this.service === 'extuni') {
        return true
      }
      return !this.taskState.final
    }
  }
}
